<template>
  <div class="product-pitch-toggle">
    <div class="w-100 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row">
        <b-checkbox
          v-model="checked"
          switch
          :disabled="addingProductToCart || removingProductFromCart"
          @change="updateChecked"
        >
          <div>
            {{ toggleLabel() }}
          </div>
        </b-checkbox>
      </div>
      <div>
        <fa-icon
          v-if="suggestion.description"
          class="question-circle pr-3 help-text"
          icon="question-circle"
          size="1x"
          @mouseenter="showPopover = true"
          @mouseleave="showPopover = false"
        />
      </div>
    </div>

    <div v-if="showPopover" class="popover">
      <div class="arrow" />
      <div class="popover-body p-3">
        <h6 class="mt-0">
          How We Work For You
        </h6>
        <p v-if="isFilingProduct" class="mb-0">
          {{ suggestion.description }}
        </p>
        <p v-else class="mb-0">
          {{ suggestion.description }} <strong>for {{ priceAndDuration }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getFieldSuggestionValue } from '@/components/StagelineV2/schemaForm/pdf/helper'
import { logSlideProductAdded } from '@/components/StagelineV2/helper'
import {
  addBoiWithFilingProduct,
  addFilingProductToBundleItems,
  addRenewalWithFilingProduct,
  raAddedToBundleItems,
} from '@/components/HireUs/helper'

export default {
  name: 'ProductPitchToggle',
  components: {},
  props: {
    field: Object,
    suggestion: Object,
  },
  data() {
    return {
      product: null,
      showPopover: false,
      addingProductToCart: false,
      removingProductFromCart: false,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'raServiceActiveOrInCart',
      'findCartItemByProductId',
      'productIdInCart',
      'renewalService',
      'renewalServiceWithJurisdictionInCart',
      'cartItems',
      'boiFilingInCart',
      'boiFiling',
      'productsLoaded',
      'findProductBy',
      'findProduct',
      'productIdInCartBundle',
      'raServiceInCart',
      'currentBundleItems',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'currentFields',
      'formData',
      'resourceFields',
    ]),
    ...mapGetters('jurisdictions', ['findByName']),
    ...mapGetters('account', ['accountType']),
    ...mapGetters('companies', ['hasExistingRAServiceInJurisdiction', 'currentCompany']),
    productActive() {
      return this.suggestion.context?.product_active || false
    },
    productActiveOrInCart() {
      return this.productActive ||
        this.productIdInCart(this.product?.id) ||
        this.productIdInCartBundle(this.product?.id)
    },
    checked: {
      get() {
        return (this.productActiveOrInCart && this.suggestionPrefilled)
      },
      async set(checked) {
        checked ? this.preFillSuggestion() : this.unFillSuggestion()
      },
    },
    jurisdiction() {
      return this.product?.kind === 'product_bundle' && this.product?.products?.length ?
        this.product?.products[0]?.filing_methods[0]?.jurisdiction :
        this.product?.jurisdiction || this.product?.filing_methods[0]?.jurisdiction
    },
    isRegisteredAgentProduct() {
      return this.suggestion.context.product_kind === 'registered_agent_product'
    },
    hasProductBundleOverride() {
      return !!this.suggestion.context?.product_bundle_override
    },
    isFilingProduct() {
      return this.suggestion.context.product_kind === 'filing_product'
    },
    isFilingWithBundleOverride() {
      return this.isFilingProduct && this.hasProductBundleOverride
    },
    price() {
      return this.suggestion.context?.price || 'TBD'
    },
    priceAndDuration() {
      const duration = this.suggestion.context.duration
      const billingConversion = {
        0: 'flat fee',
        1: 'monthly',
        12: 'yearly',
        default: `every ${duration/12} years`,
      }

      return `$${this.price} (billed ${billingConversion[duration] || billingConversion.default})`
    },
    currentFieldsWithSuggestion() {
      return this.currentFields?.length ?
        this.currentFields.filter(rf => rf.type === 'object' &&
          rf?.meta?.suggestion?.context?.product_kind === this.suggestion.context.product_kind) : []
    },
    raResourceFields() {
      return this.resourceFields.filter(rf => rf.meta.type === 'registered_agent') || []
    },
    suggestionPrefilled() {
      return this.currentFieldsWithSuggestion.every(field => {
        const self = this
        if (field?.data?.parts?.length) {
          const resourceField = self.resourceFields.find(f => f.id === field.id)
          const parts = resourceField?.data?.parts?.[0].split('.')
          const partName = parts[parts.length - 1]
          const formValue = self.formData[resourceField.id]
          const suggestionValue = resourceField?.meta?.suggestion?.[partName]
          if (!suggestionValue) return true

          const usesSuggestion = partName === 'country' ?
            this.usingCountrySuggestion(formValue, suggestionValue) :
            formValue === suggestionValue
          return !!usesSuggestion
        }
      })
    },
  },
  currentFields: {
    deep: true,
    handler(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.checked ? this.preFillSuggestion() : this.unFillSuggestion()
      }
    },
  },
  async mounted() {
    const productInfo = {
      productKind: this.suggestion.context.product_kind,
      jurisdictionId: this.suggestion.context.jurisdiction_id,
    }

    if (this.isFilingWithBundleOverride) {
      productInfo.productKind = 'product_bundle'
      productInfo.productCategory = this.suggestion.context.product_bundle_override.name
      this.product = this.findProductBy('product_bundle', { id : this.suggestion.context.product_bundle_override.product_id })
      await this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.product })
    } else if (this.isFilingProduct) {
      productInfo.productCategory = this.suggestion.context.name
    }

    if (!this.product) {
      const fetchedProduct = this.findProductBy(productInfo.productKind, {
        id : this.suggestion.context.product_id,
      }) || await this.fetchProduct(productInfo)

      this.product = (Array.isArray(fetchedProduct) && fetchedProduct.length) ?
        fetchedProduct[0] :
        fetchedProduct
    }

    if (this.isFilingWithBundleOverride) {
      this.product.filing_method = {
        id: this.suggestion.context?.filing_method_id,
      }
    } else if (this.isFilingProduct && this.product) {
      this.product.filingMethodId = this.suggestion.context?.filing_method_id
    }
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
      'removeFromCart',
      'checkForRACartItemAndRemove',
      'addRegisteredAgentAddOn',
      'enableBoiAddOn',
      'loadProducts',
      'addBundleToCart',
      'checkForBundleFreeTrialsAndCalculateTotal',
      'checkForRACartItemAndRemove',
      'removeProductIdFromCart',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'setFormValue',
    ]),

    usingCountrySuggestion(value, suggestionValue) {
      const validUSCountryValues = ['US', 'USA', 'United States']
      return value === suggestionValue ||
        validUSCountryValues.includes(value) && validUSCountryValues.includes(suggestionValue)
    },

    async updateChecked(checked) {
      // Check document progress any time this is checked
      if (checked) {
        // User toggles ON
        if (this.productActiveOrInCart) return

        this.addingProductToCart = true
        if (this.isRegisteredAgentProduct) await this.addProductToCart()
        if (this.isFilingProduct) await this.addFilingProducts()
        this.addingProductToCart = false
      } else {
        // User toggles OFF on Primary product field
        if (this.suggestion.toggle_removes_product) {
          this.removingProductFromCart = true
          await this.removeProductFromCart()
          this.removingProductFromCart = false
        }
      }
    },

    toggleLabel() {
      let label
      if (this.addingProductToCart)
        label = 'Adding to cart...'
      else if (this.removingProductFromCart)
        label = 'Removing from cart...'
      else if (this.checked)
        label = this.suggestion.selected_text
      else
        label = this.suggestion.unselected_text
      return label
    },

    async addProductToCart() {
      await this.addToCart({ ...this.product, skipResolveOptionalItems: true })
      await logSlideProductAdded(this.product)
    },

    async removeProductFromCart() {
      if (!this.product?.id) return
      const product = this.findCartItemByProductId(this.product.id)
      if (product) {
        await this.removeFromCart({ id: product?.parent_item_id || product.id })
      }
    },

    async addFilingProducts() {
      const productIsBundle = this.product.kind === 'product_bundle'
      const [addedToCart, filingProduct] = await this.addFilingProductToCart(productIsBundle)

      if (addedToCart) {
        const suggestionContext = this.suggestion.context
        const productId = productIsBundle ? suggestionContext.product_bundle_override.product_id : suggestionContext.product_id
        const parentCartItem = (this.cartItems.filter((x) => x.product_id === productId))[0]
        await logSlideProductAdded(this.product)

        if (!this.productActive && !productIsBundle) await this.addRAIfNeeded(parentCartItem)

        await this.addBoiIfNeeded(filingProduct)
        await this.addRenewalServiceIfNeeded(
          filingProduct,
          this.jurisdiction,
          parentCartItem?.id
        )
      }
    },

    async addFilingProductToCart(productIsBundle) {
      let addedToCart = false

      if (productIsBundle) {
        const filingProduct = this.product?.products.find(p => p.id === this.suggestion.context.product_id)

        filingProduct.filing_method = filingProduct?.filing_methods.find(fm =>
          fm.id === this.product.filing_method.id
        )

        // Todo - move this all into a single helper method?
        await addFilingProductToBundleItems(filingProduct)
        await raAddedToBundleItems(this.product, this.jurisdiction)

        addedToCart = await this.addBundleToCart(
          {
            bundle: {
              ...this.product,
              data: this.currentBundleItems,
            },
            removeItemsContainedInBundle: false,
          })
        return [addedToCart, filingProduct]
      } else {
        addedToCart = await this.addToCart( {
          ...this.product,
          skipResolveOptionalItems: true,
        })
        return [addedToCart, this.product]
      }
    },

    async addRAIfNeeded(parentCartItem) {
      if (!this.hasExistingRAServiceInJurisdiction(this.findByName(this.jurisdiction))) {
        await logSlideProductAdded({ name: 'Registered Agent Service', jurisdiction: this.jurisdiction, parentItemId: parentCartItem.id })
      }

      await this.addRegisteredAgentAddOn({
        parentItemId: parentCartItem.id,
        jurisdiction: { state_province_region: this.jurisdiction },
      })
    },

    async addBoiIfNeeded(filingProduct) {
      const boiAdded = await addBoiWithFilingProduct(filingProduct)
      if (boiAdded) {
        await logSlideProductAdded({
          ...this.boiFiling,
          filingMethodId: this.boiFiling.filing_methods[0]?.id,
        })
      }
    },

    async addRenewalServiceIfNeeded(product, jurisdiction, parentItemId = null) {
      const renewalAdded = await addRenewalWithFilingProduct({
        filingProduct: product,
        company: this.currentCompany,
        jurisdiction: jurisdiction,
        productCartItemId: parentItemId,
        skipResolveOptionalItems: true,
      })

      if (renewalAdded) {
        await logSlideProductAdded({ ...this.renewalService, parentItemId: parentItemId })
      }
    },

    preFillSuggestion() {
      const self = this
      this.currentFieldsWithSuggestion.forEach(field => {
        if (field?.data?.parts?.length && getFieldSuggestionValue(field)) {
          const resourceField = self.resourceFields.find(f => f.id === field.id)
          resourceField.data.disabled = true
          this.setFormValue({
            key: field.id,
            value: getFieldSuggestionValue(field),
          })
        }
      })
      this.preFillRaSuggestion()
    },

    preFillRaSuggestion() {
      const self = this
      this.raResourceFields.forEach(field => {
        const resourceField = self.resourceFields.find(f => f.id === field.id)
        resourceField.data.disabled = true
        this.setFormValue({
          key: field.id,
          value: getFieldSuggestionValue(field),
        })
      })
    },

    unFillSuggestion() {
      const self = this
      this.currentFieldsWithSuggestion.forEach(field => {
        const resourceField = self.resourceFields.find(f => f.id === field.id)

        if (field?.data?.parts?.length && resourceField.data.disabled) {
          resourceField.data.disabled = false
          this.setFormValue({
            key: field.id,
            value: null,
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">

.product-pitch-toggle {
  $bg: #213C9C;
  position: relative;

  .custom-control-label {
    display: flex;
  }

  .custom-switch .custom-control-label:before {
    background: #ddd;
    cursor: pointer;
    display: block;
    position: relative;
    float: left;
    top: -0.125rem;
    height: 1.875rem;
    width: 3.5rem;
    margin-right: -1.25rem;
    border-radius: 1rem;
    border: none;
    transition: all 100ms linear;
  }

  .custom-switch .custom-control-label:after {
    background: #fff;
    cursor: pointer;
    content: "";
    display: block;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0.0625rem;
    margin-left: 0.125rem;
    border-radius: 100%;
    transition: all 100ms linear;
    z-index: 2;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;
  }

  .popover {
    width: 100%;
    position: absolute;
    top: 33px;
    z-index: 20;
    transform: unset;
    max-width: unset;
    .arrow {
      position: absolute;
      right: 11px;
      top: -9px;
      &::before, &::after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $bg;
      }
    }

    .popover-body {
      border-radius: 3px;
      color: white !important;
      background: $bg !important;
    }
  }

  .question-circle {
    color: $ct-ui-primary !important;
  }
}

</style>
